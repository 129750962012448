export const defaultProps = {
  easing: "cubic-bezier(0.5, 0, 0, 1)",
  distance: "30px",
  duration: 1000,
  desktop: true,
  mobile: true,
};

export const targetElements = [
  {
    element: ".section-title",
    animation: {} // Empty animation object
  },
  {
    element: ".hero-title",
    animation: {} // Empty animation object
  },
  {
    element: ".hero-cta",
    animation: {} // Empty animation object
  },
  {
    element: ".about-wrapper__image",
    animation: {} // Empty animation object
  },
  {
    element: ".about-wrapper__info",
    animation: {} // Empty animation object
  },
  {
    element: ".project-wrapper__text",
    animation: {} // Empty animation object
  },
  {
    element: ".project-wrapper__image",
    animation: {} // Empty animation object
  },
  {
    element: ".contact-wrapper",
    animation: {} // Empty animation object
  },
];
